export const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);

//reCaptcha Enterprise Key
export const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_ENTERPRISE_KEY;

export function debugToken() {
  if (process.env.REACT_APP_WORKING_ENVIRONMENT === "DEV") {
    // Set this to "True" if you want to get a new AppCheck Debug Token for a Browser or a Machine or
    // If you want to use the token in another browser or on another machine, set self.FIREBASE_APPCHECK_DEBUG_TOKEN to the token string instead
    // eslint-disable-next-line no-restricted-globals
    self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
  }
}
