import { RemoteLogger } from "@Ethical-Algorithm/reactjs-ea-logger-kit";
import * as Sentry from "@sentry/react";
import { logEvent, setUserId } from "firebase/analytics";
import { analytics } from "../FirebaseConfig/Firebase";

class SentryRemoteLogger extends RemoteLogger {
  login(userInfo) {
    setUserId(analytics, userInfo.id);
    Sentry.setUser({ id: userInfo.id, username: userInfo.username });
  }

  logout() {
    setUserId(analytics, "anonymous");
    Sentry.setUser({ id: "anonymous", username: "anonymous" });
  }

  //============| Loggers |============//

  //Error Logger
  log(message) {
    logEvent(analytics, "log", { message: message });
    Sentry.captureMessage(message);
  }

  //Error Logger
  error(message, error) {
    if (error) {
      logEvent(analytics, "error", { error: error });
      Sentry.captureException(error, (scope) => {
        if (message) {
          scope.setTransactionName(message);
        }
        return scope;
      });
    } else if (message) {
      logEvent(analytics, "error", { message: message });
      Sentry.captureMessage(message);
    }
  }

  breadcrumb(action, message, level) {
    logEvent(analytics, "breadcrumb", {
      action: action,
      message: message,
      level: level,
    });
    Sentry.addBreadcrumb({
      action: action,
      message: message,
      level: level,
    });
  }
}

export default SentryRemoteLogger;
