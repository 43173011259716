import LazyLoad from "react-lazyload";

//Material UI / Styles
import { Box, Grid, IconButton, Typography, styled } from "@mui/material";
import styles from "pages/Company/company.module.css";

//Assets
import placeHolder from "assets/Placeholders/ic_placeholder_4_by_3.svg";
import ic_add from "assets/icons/ic_add.svg";
import ic_info from "assets/icons/ic_info2.svg";

//Utils
import useCurrencyFormatter from "utils/Hooks/useCurrencyFormatter";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import { getSmallestItemPrice } from "utils/getSmallestItemPrice";
import { removeNullValues } from "utils/removeNullValues";
import { monitoring } from "@Ethical-Algorithm/reactjs-ea-logger-kit";

const StyledIconButton = styled(IconButton)({
  backgroundColor: "#F2F2F2 !important",
  borderRadius: "20px",
  padding: "8px",
  color: "#000",
});

export default function Product({
  product,
  setOpen,
  setSelectedProduct,
  addToCart,
  defaultLanguage,
  currencyCode,
  userLanguage,
}) {
  const formatCurrency = useCurrencyFormatter(); //Price formater to current currency | formatter.format(price)

  // Remove the null values from the portions array
  const portions =
    product?.portions || [].map((i) => removeNullValues(i.translation));

  //Get the smallest price of the product
  const smallestPricePortion = getSmallestItemPrice(portions);

  return (
    <Box
      sx={{
        p: "12px 16px",
        borderBottom: "1px solid #DADADA50",
        cursor: "pointer",
      }}
      onClick={() => {
        setOpen(true);
        setSelectedProduct(product);

        //TODO: Remove this later [Analytics]
        // logMenuItem(
        //   product.companyID,
        //   product.id,
        //   product.title[defaultLanguage] ||
        //     product.title[userLanguage] ||
        //     Object.values(product.title)[0],
        //   product.category
        // );

        monitoring.breadcrumb(
          "[View Item]",
          `Viewed Item: ${
            product.title[defaultLanguage] ||
            product.title[userLanguage] ||
            Object.values(product.title)[0]
          }, ProductID: ${product.id}, CompanyID: ${product.companyID}`,
          "info"
        );
      }}
      className={styles.swButtonBase}
    >
      <Grid
        sx={{
          height: "110px",
          alignItems: "center",
          flexWrap: "nowrap",
          justifyContent: "space-between",
        }}
        key={product?.id}
        container
      >
        <Grid sx={{ display: "flex" }}>
          {/*============= Banner =============*/}

          <LazyLoad
            once
            placeholder={<img src={placeHolder} alt="placeholder" />}
          >
            <img
              alt="Banner"
              src={!product?.imageUrl ? placeHolder : product?.imageUrl}
              className={styles.menuImage}
            />
          </LazyLoad>

          {/*============= Text =============*/}

          <Box
            sx={{
              overflow: "hidden",
              width: "168px",
              height: "106.21px",
              alignItems: "center",
              display: "flex",
            }}
          >
            <div>
              <Typography sx={{ fontSize: "15px", lineHeight: "18px" }}>
                {capitalizeFirstLetter(
                  product.title[userLanguage] ||
                    product.title[defaultLanguage] ||
                    Object.values(product.title)[0]
                )}
              </Typography>

              <Typography
                sx={{
                  fontSize: "13px",
                  color: "#646464",
                  mt: "4px",
                  lineHeight: "16px",
                }}
              >
                {smallestPricePortion.price &&
                smallestPricePortion.price !== Infinity &&
                smallestPricePortion.price !== 0
                  ? `${currencyCode} ${formatCurrency(
                      smallestPricePortion.price
                    )}`
                  : "No Product Price Available"}
              </Typography>

              <Typography
                sx={{
                  fontSize: "13px",
                  color: "#646464",
                  mt: "6px",
                  lineHeight: "16px",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 3,
                  overflow: "hidden",
                  maxWidth: "168px",
                }}
              >
                {product.ingredients[userLanguage] ||
                  product.ingredients[defaultLanguage] ||
                  Object.values(product.ingredients)[0]}
              </Typography>
            </div>
          </Box>
        </Grid>
        {/*============= Button Icon =============*/}
        <Grid item xs={1}>
          <StyledIconButton
            disabled={smallestPricePortion.price === Infinity}
            aria-label="add"
            size="small"
            onClick={(event) => {
              addToCart({
                selectedProduct: product,
                selectedPortion: smallestPricePortion,
                selectedExtras: [],
              });
              //TODO: Remove this later [Analytics]
              // logAddToCart(
              //   product.companyID,
              //   product.id,
              //   product.title[defaultLanguage] ||
              //     product.title[userLanguage] ||
              //     Object.values(product.title)[0],
              //   product.category,

              //   smallestPricePortion,
              //   currencyCode
              // );
              monitoring.breadcrumb(
                "[Add to Cart]",
                `Added to Cart: ${
                  product.title[defaultLanguage] ||
                  product.title[userLanguage] ||
                  Object.values(product.title)[0]
                }, ProductID: ${product.id}, CompanyID: ${product.companyID}`,
                "info"
              );

              event.stopPropagation();
            }}
          >
            {smallestPricePortion.price === Infinity ? (
              <img alt="info" src={ic_info} />
            ) : (
              <img alt="add" src={ic_add} />
            )}
          </StyledIconButton>
        </Grid>
      </Grid>
    </Box>
  );
}
