import { useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Grid, IconButton, styled, Collapse } from "@mui/material";
import QuantityInput from "./QuantityInput";

//Assets
import PlaceHolder from "assets/Placeholders/ic_placeholder_4_by_3.svg";
import ic_edit from "assets/icons/ic_edit.svg";

//Utils
import useCurrencyFormatter from "utils/Hooks/useCurrencyFormatter";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";

const StyledIconButton = styled(IconButton)({
  "&:active": {
    backgroundColor: "#594ED6 !important",
  },
  padding: "8px",
  backgroundColor: "#fff !important",
  borderRadius: "50%",
  boxShadow: "0px 0px 24px 0px rgba(0, 0, 0, 0.06)",
});

export default function ProductCart({
  item,
  currencyCode,
  defaultLanguage,
  userLanguage,
}) {
  const dispatch = useDispatch();
  const formatCurrency = useCurrencyFormatter();

  const [isEdit, setIsEdit] = useState(false);

  const addQuantity = (item) => {
    dispatch({ type: "myList/addQuantity", payload: item.id });
  };
  const removeQuanity = (item) => {
    if (item.quantity > 0) {
      dispatch({ type: "myList/removeQuantity", payload: item.id });
    }
  };

  const productPrice =
    item.portion.price +
    item.extras?.reduce((acc, extra) => acc + extra.price, 0);

  return (
    <Grid
      container
      sx={{
        alignItems: "center",
        flexWrap: "nowrap",
        justifyContent: "space-between",
        padding: "12px 16px 12px 16px",
        borderBottom: "1px solid #DADADA50",
      }}
    >
      <Grid item sx={{ display: "flex", gap: "16px", height: "58px" }} xs>
        <Box>
          <img
            alt="PlaceHolder"
            src={item.info.imageUrl ? item.info.imageUrl : PlaceHolder}
            style={{
              height: "58px",
              width: "48px",
              objectFit: "cover",
              objectPosition: "center",
              borderRadius: "8px",
              border: "1px solid rgba(0, 0, 0, 0.05)",
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div style={{ fontSize: "18px" }}>
            {capitalizeFirstLetter(
              item.info.title[userLanguage] ||
                item.info.title[defaultLanguage] ||
                Object.values(item.info.title)[0]
            )}
          </div>
          <div style={{ display: "flex", marginTop: "4px" }}>
            <div style={{ display: "flex" }}>x{item.quantity}</div>
            <div style={{ marginLeft: "5px", marginRight: "5px" }}>•</div>
            <div>
              {currencyCode} {formatCurrency(productPrice)}
            </div>
            <div
              style={{
                fontSize: "13px",
                alignItems: "center",
                display: "flex",
                color: "#00000090",
                marginLeft: "5px",
              }}
            >
              {capitalizeFirstLetter(
                item.portion.translation[userLanguage] ||
                  item.portion.translation[defaultLanguage] ||
                  Object.values(item.portion.translation)[0]
              )}
              {item.extras.length > 0 && " + Extras"}
            </div>
          </div>
        </Box>
      </Grid>

      {isEdit ? (
        // ===============| Quantity Inputs |================ //
        <Collapse orientation="horizontal" in={isEdit} collapsedSize={32}>
          <QuantityInput
            item={item}
            removeQuanity={removeQuanity}
            addQuantity={addQuantity}
          />
        </Collapse>
      ) : (
        // ===============| Edit Button |============= //
        <Collapse orientation="horizontal" in={isEdit} collapsedSize={32}>
          <Grid item xs={0}>
            <StyledIconButton onClick={() => setIsEdit(true)}>
              <img src={ic_edit} alt="Edit" />
            </StyledIconButton>
          </Grid>
        </Collapse>
      )}
    </Grid>
  );
}
