import React from "react";

export default function PageNotFound() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        fontSize: "48px",
        fontWeight: "bold",
        color: "#000",
        backgroundColor: "#F5F5F5",
        fontFamily: "Roboto, sans-serif",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <div style={{ fontSize: "72px", marginBottom: "16px" }}>404</div>
        <p style={{ fontSize: "24px", marginBottom: "32px" }}>
          Page Not Found !
        </p>
      </div>
    </div>
  );
}
