//Components
import Navbar from "./components/Navbar";
import Intro from "./components/Intro";
import Steps from "./components/Steps";
import Info from "./components/Info";
import Footer from "./components/Footer";
import ScanQR from "./components/ScanQR";
// import Pricing from "./components/Pricing";

//TODO: Add it later - Feature Components
// import Brands from "./components/Brands";
// import Video from "./components/Video";

export default function Home() {
  return (
    <div>
      <Navbar />
      <div className="homeContainer">
        <Intro />
        {/* <Brands /> */}
        <Steps />
        {/* <Video /> */}
        <Info />
        {/* <Pricing /> */}
        <Footer />
        <ScanQR />
      </div>
    </div>
  );
}
