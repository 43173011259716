import { forwardRef, useEffect, useState } from "react";

//Material Ui
import { Box, Grid, Slide } from "@mui/material";
import Dialog from "@mui/material/Dialog";

//Styles
import styles from "./Details.module.css";

//Assets
import PlaceHolder from "assets/Placeholders/ic_placeholder_16_by_9.svg";

//Utils
import { getSmallestItemPrice } from "utils/getSmallestItemPrice";
import { removeNullValues } from "utils/removeNullValues";

//Components
import AddToListButton from "./components/AddToListButton";
import ProductInfo from "./components/ProductInfo";
import ProductPortions from "./components/ProductPortions";
import ProductExtras from "./components/ProductExtras";
import CloseButton from "./components/CloseButton";
import { monitoring } from "@Ethical-Algorithm/reactjs-ea-logger-kit";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Details({
  open,
  setOpen,
  selectedProduct,
  addToCart,
  defaultLanguage,
  currencyCode,
  userLanguage,
  features,
}) {
  const portions =
    selectedProduct?.portions || [].map((i) => removeNullValues(i.translation)); // Remove the null values from the translations

  const extras =
    selectedProduct?.extras || [].map((i) => removeNullValues(i.translation)); // Remove the null values from the translations

  //================================================================
  const [selectedExtras, setSelectedExtras] = useState([]);
  const [selectedPortion, setSelectedPortion] = useState(
    getSmallestItemPrice(portions)
  );

  //======================| Prices |======================
  //Price of the selected portion (used to show the difference between prices)
  const selectedPortionPrice = selectedPortion && selectedPortion.price;

  //Total price of the product(portion + extras) (used to show the total price)
  const totalProductPrice =
    Number(selectedPortionPrice) +
    selectedExtras.reduce((acc, extra) => acc + Number(extra.price), 0);

  //===Reset the selected portions to normal when the bottom sheet is opening ===
  useEffect(() => {
    if (open) {
      //Reset the selected portion to the smallest Item Price when the bottom sheet is opened
      setSelectedPortion(getSmallestItemPrice(portions));
    } else {
      //Reset the selected extras to an empty array when the bottom sheet is closed
      setSelectedExtras([]);
    }
    // eslint-disable-next-line
  }, [open]);

  //======================| Add To Cart |======================
  const handleAddToCart = () => {
    addToCart({
      selectedProduct,
      selectedPortion,
      selectedExtras,
    });
    setOpen(false);

    monitoring.action(
      "[Add To Cart]",
      `Added to Cart: ${
        selectedProduct.title[defaultLanguage] ||
        selectedProduct.title[userLanguage] ||
        Object.values(selectedProduct.title)[0]
      }, ProductID: ${selectedProduct.id}, CompanyID: ${
        selectedProduct.companyID
      }`,
      "info"
    );
  };

  return (
    <Box sx={{ bgcolor: "#000" }}>
      <Dialog
        fullScreen
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            borderRadius: "16px 16px 0 0",
            backgroundColor: "#F5F5F5",
          },
        }}
        className={styles.detailsDialog}
      >
        <Grid container sx={{ p: "0px", mb: "81px" }}>
          {/*============== Banner ==============*/}
          <Grid container className={styles.stickyHeader}>
            <div
              style={{
                backgroundImage: `url(${
                  selectedProduct.imageUrl
                    ? selectedProduct.imageUrl
                    : PlaceHolder
                })`,
              }}
              className={styles.banner}
            />

            <ProductInfo
              selectedProduct={selectedProduct}
              defaultLanguage={defaultLanguage}
              currencyCode={currencyCode}
              userLanguage={userLanguage}
              totalProductPrice={totalProductPrice}
            />
          </Grid>

          <div className={styles.flexColumnContainer}>
            <ProductPortions
              portions={portions}
              setSelectedPortion={setSelectedPortion}
              selectedPortion={selectedPortion}
              selectedPortionPrice={selectedPortionPrice}
              currencyCode={currencyCode}
              //-----
              userLanguage={userLanguage}
              defaultLanguage={defaultLanguage}
            />

            <ProductExtras
              extras={extras}
              setSelectedExtras={setSelectedExtras}
              selectedPortion={selectedPortion}
              selectedExtras={selectedExtras}
              currencyCode={currencyCode}
              //-----
              userLanguage={userLanguage}
              defaultLanguage={defaultLanguage}
            />
          </div>
        </Grid>

        {/* ==================| Floating action buttons |================== */}
        <CloseButton setOpen={setOpen} />

        <AddToListButton
          features={features}
          handleAddToCart={handleAddToCart}
          disabled={
            portions?.some((portion) => portion.id === selectedPortion?.id)
              ? false
              : true
          }
        />
      </Dialog>
    </Box>
  );
}
