import { useTranslation } from "react-i18next";

//CSS Module
import styles from "./ScanQR.module.css";

//Assets
import img_qr_code from "assets/img_qr_code.svg";

//Utils
import { isTabletOrMobileDevice } from "utils/detectMobile";

//Router
import { useNavigate } from "react-router-dom";
import { ROUTES } from "App/Routes";

export default function ScanQR() {
  const { t } = useTranslation();
  const isTabletOrMobile = isTabletOrMobileDevice();
  const navigate = useNavigate();

  const defaultCompanyID = "09bc9533-cc8f-462f-ae9c-176fd3697060";

  const handleQRCodeClick = () => {
    if (isTabletOrMobile) {
      navigate(`${ROUTES.COMPANY}/${defaultCompanyID}`);
      //reset scroll position
      window.scrollTo(0, 0);
    }
    return;
  };

  return (
    <div className={styles.QRContainer} onClick={handleQRCodeClick}>
      <div className={styles.QRCard} id="#QRCard">
        {/* ======| Background Effects/Images |====== */}
        <div className={styles.cardEffect} />
        <div className={styles.cardEffect2} />
        <div className={styles.cardImage}>🤳🏻</div>
        <div className={styles.cardImage2}>✨</div>
        {/* ================================== */}
        <div className={styles.QRCardText}>
          {isTabletOrMobile
            ? t("Home.lb_scan_click_qr_code")
            : t("Home.lb_scan_qr_banner")}
          →
        </div>
        <img className={styles.QRCode} src={img_qr_code} alt="img_qr_code" />
      </div>
    </div>
  );
}
